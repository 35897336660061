/**
 * UI Reducer
 * Manages global ui state
 */

const initialState = {
  navigation: {
    active: false,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'setNavigation':
      return {
        navigation: {
          active: action.payload,
        },
      };

    default:
      throw new Error(`Unhandled action type ${action.type}`);
  }
};

export { initialState, reducer };
