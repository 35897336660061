import React, { useContext, useReducer } from 'react';

import UIContext from './UIContext';
import { reducer, initialState } from './UIReducer';

function UIProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <UIContext.Provider value={[state, dispatch]}>
      {children}
    </UIContext.Provider>
  );
}

export function useUI() {
  return useContext(UIContext);
}

export default UIProvider;
